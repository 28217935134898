import logo from './logo.svg';
import './App_mobile.css';
import './App_desktop.css';
import Joke from './Joke/Joke.js';
import Image from './Image/Image.js';
import Finance from './Finance/Finance.js';
import TodoApp from './Todo/TodoApp.js';
import Nav from "./Nav/Nav.js";
import Footer from './Footer/Footer.js';

function App() {
  return (
    <>
    <div>
      <header>Dash.board</header>
      <main className='dashboard'>
        <Joke />
        <Image />
        <Finance />
        <TodoApp />
      </main>
      <Nav />
    </div>
      <Footer />
    </>
  );
}

export default App;
