export default function Geld_icon({ fillColor, onClick }) {
  return (
    <svg
      id="todo_icon"
      fill={fillColor}
      onClick={()=>{onClick('todo')}}
      xmlns="http://www.w3.org/2000/svg"
      height="2em"
      viewBox="0 -960 960 940"
      width="2em"
    >
      <path d="M280-600v-80h560v80H280Zm0 160v-80h560v80H280Zm0 160v-80h560v80H280ZM160-600q-17 0-28.5-11.5T120-640q0-17 11.5-28.5T160-680q17 0 28.5 11.5T200-640q0 17-11.5 28.5T160-600Zm0 160q-17 0-28.5-11.5T120-480q0-17 11.5-28.5T160-520q17 0 28.5 11.5T200-480q0 17-11.5 28.5T160-440Zm0 160q-17 0-28.5-11.5T120-320q0-17 11.5-28.5T160-360q17 0 28.5 11.5T200-320q0 17-11.5 28.5T160-280Z" />
    </svg>
  );
}
