export default function Home_icon({ fillColor, onClick }) {
  return (
    <svg
      id="home_icon"
      fill={fillColor}
      onClick={()=>{onClick('home')}}
      xmlns="http://www.w3.org/2000/svg"
      height="2em"
      viewBox="0 -960 900 960"
      width="2em"
      fontSize="1.5em"
    >
      <path d="M160-120v-480l320-240 320 240v480H560v-280H400v280H160Z" />
    </svg>
  );
}
