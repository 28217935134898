import { useState, useEffect } from "react";
import "./Nav.css";
import Geld_icon from "../assets/svg/Geld_icon";
import Home_icon from "../assets/svg/Home_icon";
import Todo_icon from "../assets/svg/Todo_icon";

export default function Nav() {
  useEffect(() => {
    auswahl_anzeigen("home");
  }, []);
  function auswahl_anzeigen(auswahl) {
    const Finance = document.querySelector(".finance");
    const Image = document.querySelector(".image");
    const Joke = document.querySelector(".joke");
    const Todo = document.querySelector(".todo");
    const Geld_icon = document.getElementById("geld_icon");
    const Home_icon = document.getElementById("home_icon");
    const Todo_icon = document.getElementById("todo_icon");

    Geld_icon.setAttribute(
      "fill",
      auswahl === "geld" ? "rgb(0, 191, 255)" : "white"
    );
    Home_icon.setAttribute(
      "fill",
      auswahl === "home" ? "rgb(0, 191, 255)" : "white"
    );
    Todo_icon.setAttribute(
      "fill",
      auswahl === "todo" ? "rgb(0, 191, 255)" : "white"
    );

    Finance.style.display = auswahl === "geld" ? "inherit" : "none";
    Image.style.display = auswahl === "home" ? "inherit" : "none";
    Joke.style.display = auswahl === "home" ? "inherit" : "none";
    Todo.style.display = auswahl === "todo" ? "inherit" : "none";
  }
  return (
    <nav className="nav">
      <Geld_icon fillColor={"white"} onClick={auswahl_anzeigen} />
      <Home_icon fillColor={"white"} onClick={auswahl_anzeigen} />
      <Todo_icon fillColor={"white"} onClick={auswahl_anzeigen} />
    </nav>
  );
}
